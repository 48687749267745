<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="120px"
    >
      <div><b>Баннер</b></div>
      <el-form-item label="Заголовок" prop="titleSection.title">
        <el-input
          v-model="form.titleSection.title"
          data-test-id="titleSection_title"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="Подзаголовок" prop="titleSection.subTitle">
        <el-input
          v-model="form.titleSection.subTitle"
          data-test-id="titleSection_subTitle"
          type="textarea"
        />
      </el-form-item>

      <div><b>Блок Преимущества</b></div>
      <el-form-item label="Заголовок" prop="advantagesSection.title">
        <el-input v-model="form.advantagesSection.title" type="textarea" />
      </el-form-item>
      <el-form-item label="Пункты" prop="advantagesSection.content">
        <span>
          Добавьте {count}, чтобы оно преобразовалось в количество дилеров(
          {{ dealerCount }}
          )
        </span>
        <div
          v-for="(item, index) in form.advantagesSection.content"
          :key="index"
          :class="$style.solutionContent"
        >
          <el-select v-model="item.iconName" placeholder="Выберите">
            <el-option
              v-for="icon in advantagesSectionIcons"
              :key="icon.value"
              :label="icon.label"
              :value="icon.value"
            >
            </el-option>
          </el-select>
          <el-input v-model="item.text" type="textarea" size="mini" />
        </div>
      </el-form-item>

      <div><b>Блок Процесс работы </b></div>
      <el-form-item label="Заголовок" prop="processesSection.title">
        <el-input v-model="form.processesSection.title" type="textarea" />
      </el-form-item>
      <el-form-item label="Пункты" prop="processesSection.content">
        <div
          v-for="(item, index) in form.processesSection.content"
          :key="index"
          :class="$style.solutionContent"
        >
          <el-select v-model="item.iconName" placeholder="Выберите">
            <el-option
              v-for="icon in processesSectionIcons"
              :key="icon.value"
              :label="icon.label"
              :value="icon.value"
            >
            </el-option>
          </el-select>
          <el-input v-model="item.text" type="textarea" size="mini" />
        </div>
      </el-form-item>

      <div><b>Блок Присоединяйтесь</b></div>
      <el-form-item label="Заголовок" prop="registrationSection.title">
        <el-input v-model="form.registrationSection.title" type="textarea" />
      </el-form-item>
      <el-form-item
        label="Текст кнопки"
        prop="registrationSection.content[0].text"
      >
        <el-input v-model="form.registrationSection.content[0].text" />
      </el-form-item>
      <el-form-item
        label="Отн ссылка"
        prop="registrationSection.content[0].link"
      >
        <el-input v-model="form.registrationSection.content[0].link" />
      </el-form-item>

      <div><b>Блок Варианты сотрудничества</b></div>
      <el-form-item label="Заголовок" prop="workAndCooperationSection.title">
        <el-input
          v-model="form.workAndCooperationSection.title"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="Пункты" prop="workAndCooperationSection.content">
        <el-button
          style="margin-bottom: 1rem"
          type="primary"
          icon="el-icon-plus"
          @click="addWorkAndCooperationSectionContentItem"
        >
          Добавить
        </el-button>
        <div
          v-for="(item, index) in form.workAndCooperationSection.content"
          :key="index"
          :class="$style.solutionContent"
        >
          <el-form-item label="Заголовок" style="width: 100%">
            <el-input v-model="item.text" type="textarea" />
          </el-form-item>
          <el-form-item label="Описание" style="width: 100%">
            <el-input v-model="item.description" type="textarea" size="mini" />
          </el-form-item>
          <el-button
            icon="el-icon-delete-solid"
            @click="removeWorkAndCooperationSectionItem(index)"
          />
        </div>
      </el-form-item>

      <div><b> Блок Удобный интерфейс</b></div>
      <el-form-item label="Заголовок" prop="workAndCooperationSection.title">
        <el-input
          v-model="form.comfortableInterfaceSection.title"
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="Пункты" prop="workAndCooperationSection.content">
        <el-button
          style="margin-bottom: 1rem"
          type="primary"
          icon="el-icon-plus"
          @click="addComfortableInterfaceSectionItem()"
        >
          Добавить
        </el-button>
        <div
          v-for="(item, index) in form.comfortableInterfaceSection.content"
          :key="index"
          :class="$style.solutionContent"
        >
          <el-select v-model="item.iconName" placeholder="Выберите">
            <el-option
              v-for="icon in comfortableInterfaceSectionIcons"
              :key="icon.value"
              :label="icon.label"
              :value="icon.value"
            >
            </el-option>
          </el-select>
          <el-form-item
            label="Заголовок"
            label-width="80px"
            style="width: 100%"
          >
            <el-input v-model="item.text" type="textarea" />
          </el-form-item>
          <el-form-item label="Описание" label-width="80px" style="width: 100%">
            <el-input v-model="item.description" type="textarea" size="mini" />
          </el-form-item>
          <el-button
            icon="el-icon-delete-solid"
            @click="removeComfortableInterfaceSectionItem(index)"
          />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'

export default {
  data() {
    return {
      form: {
        titleSection: {},
        advantagesSection: {
          content: []
        },
        processesSection: {
          content: []
        },
        registrationSection: {
          title: '',
          content: [{}]
        },
        workAndCooperationSection: {
          content: []
        }
      },
      advantagesSectionIcons: [
        { value: 'finance', label: 'Отчеты' },
        { value: 'boxOk', label: 'Успешная доставка' },
        { value: 'communicate', label: 'Быстрый доступ' },
        { value: 'boxFast', label: 'Быстрая доставка' }
      ],
      processesSectionIcons: [
        { value: 'circle1', label: '1' },
        { value: 'circle2', label: '2' },
        { value: 'circle3', label: '3' },
        { value: 'circleOk', label: 'Успех' }
      ],
      comfortableInterfaceSectionIcons: [
        { value: 'chat', label: 'Чат' },
        { value: 'mapCard', label: 'Карта' },
        { value: 'alert', label: 'Подписки' },
        { value: 'price', label: 'РРЦ' },
        { value: 'realTime', label: 'График' },
        { value: 'settings', label: 'Параметры' }
      ],
      dealerCount: 0,
      rules: {
        titleSection: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          subTitle: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        },
        advantagesSection: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          content: {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change'
          }
        },
        processesSection: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          content: {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change'
          }
        },
        registrationSection: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          content: {
            0: {
              text: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur'
              },
              link: {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur'
              }
            }
          }
        },
        workAndCooperationSection: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          content: {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change'
          }
        }
      }
    }
  },
  created() {
    this.getPage('suppliers')
    this.getDealerCount()
  },
  methods: {
    async getPage(slug) {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(slug, 'sellers')
      loading.close()
      if (error) return

      this.form = { ...this.form, ...value.content }

      if (
        !this.form?.advantagesSection?.content ||
        this.form?.advantagesSection?.content.length !== 4
      ) {
        this.form.advantagesSection.content = []
        for (let i = 0; i < 4; i++) {
          this.form.advantagesSection.content.push({
            iconName: '',
            text: ''
          })
        }
      }
      if (
        !this.form?.processesSection?.content ||
        this.form?.processesSection?.content.length !== 4
      ) {
        this.form.processesSection.content = []
        for (let i = 0; i < 4; i++) {
          this.form.processesSection.content.push({
            iconName: '',
            text: ''
          })
        }
      }
      if (!this.form?.workAndCooperationSection?.content) {
        this.form.workAndCooperationSection.content = []
      }
      if (!this.form.comfortableInterfaceSection.content) {
        this.form.comfortableInterfaceSection.content = []
      }
    },
    async getDealerCount() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.AddwineCore.DealersActions.getDealersCount()
      loading.close()
      if (error) return

      this.dealerCount = value
    },
    addWorkAndCooperationSectionContentItem() {
      this.form.workAndCooperationSection.content.push({
        text: '',
        description: ''
      })
    },
    removeWorkAndCooperationSectionItem(index) {
      this.form.workAndCooperationSection.content.splice(index, 1)
    },
    addComfortableInterfaceSectionItem() {
      this.form.comfortableInterfaceSection.content.push({
        iconName: '',
        text: '',
        description: ''
      })
    },
    removeComfortableInterfaceSectionItem(index) {
      this.form.workAndCooperationSection.content.splice(index, 1)
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const result = await delivery.ContentServiceCore.PagesActions.update(
            'suppliers',
            'sellers',
            this.form
          )
          if (result.error) {
            alert('Ошибка обновлена страницы: ' + result.error)
          } else {
            alert('Старница успешно обновлена')
          }
          loading.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .solutionContent {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .cards {
    display: flex;
    .card {
      width: 100%;
    }
  }
}
</style>
